// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
//requre("@rails/uje").start()
//Turbolinks.start()
ActiveStorage.start()

window.Rails = Rails;

require("jquery");
require("jquery-ui/ui/widgets/datepicker");

window.Noty = require("noty")
window.Dropzone = require("dropzone")


//window.BulmaCarousel = require("bulma-extensions/bulma-carousel/dist/js/bulma-carousel")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//require("trix")
//require("@rails/actiontext")

//import "./submit_on_ajax.js"
//import "../common/submit_on_ajax"
//require("common/submit_on_ajax")
